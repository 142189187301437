import React from 'react';
import useIsMobile from '../../hooks/useIsMobile';

function PrivacyPolicyPage() {
  const { isMobile } = useIsMobile();

  const copyEmail = () => {
    try {
      const email = 'support@saversapp.com';
      navigator.clipboard.writeText(email);
      alert('Email copied to clipboard');
    } catch (error) {
      console.log('error: ', error);
    }
  };
  return (
    <div
      className={`px-4 md:px-24 mx-auto py-10 leading-6 ${
        isMobile ? 'px-5' : ''
      }`}
    >
      {/* {isMobile && (
        <button
          className='mt-2 mb-6 block'
          onClick={() => window.history.back()}
        >
          <FaArrowLeft />
        </button>
      )} */}
      {/* First Section */}
      <section>
        {!isMobile && (
          <h1 className='text-[1.25rem] leading-[2rem] md:text-[40px] text-[#2D2D2D] md:leading-[40px] mb-8 font-bold'>
            Program Privacy Policy
          </h1>
        )}
        <p className='mb-6'>Effective Date: 1st August, 2024</p>
        <p>
          Your privacy is important to us and we want you to feel comfortable
          using the Savers App Inc. Program (
          <strong>“Savers App”, “we”, “us”</strong> or <strong>“our”</strong> as
          the context dictates) as it is described and outlined in the Program
          Terms. Given this, we have created this Privacy Policy (the{' '}
          <strong>"Privacy Policy"</strong>) which applies to any person using
          the Program and shall be read in conjunction with our Program Terms.{' '}
        </p>
        <p className='mt-4'>
          This Privacy Policy explains the data protection and usage practices
          of Savers App Inc. when you join or use the program and applications,
          to which this policy is linked.
        </p>
      </section>

      {/* New Section */}
      <section className='px-2 md:px-0'>
        <ol className='list-decimal mt-8 bold-marker ml-[1.2rem]'>
          <li className='mb-5'>
            <h3 className='font-bold'>GENERAL</h3>
            <p className='mt-2'>
              To provide you with the benefits of the Program, we need to
              collect information, which may also include your personal
              information or data, as defined under the applicable law. Please
              be aware that your participation and use of our program may also
              result in the collection of certain information on account of
              technologies detailed in our Privacy Policy.
            </p>
          </li>

          <li className='mb-5'>
            <h3 className='font-bold'>
              INFORMATION WE COLLECT THROUGH THE PROGRAM
            </h3>
            <div className='mt-2'>
              <p className='mt-2'>
                We collect and use several types of information from and about
                you for the Program, including:
              </p>
              <p className='mt-6'>
                <strong>Personal information</strong>, that we can reasonably
                use to directly or indirectly identify you, such as your name,
                email address, phone number, Internet protocol (IP) address used
                to connect your computer to the Internet, login/username, or
                other similar identifier, and any other identifier we may use to
                contact you (<strong>"personal information"</strong>).
              </p>
              <p className='mt-6'>
                We provide an opportunity for you to unsubscribe or opt-out of
                contact on an ongoing basis by using the unsubscribe mechanism
                at the bottom of our e-mails, in the account setting section of
                the APP, or by e-mailing to{' '}
                {isMobile ? (
                  <p
                    className='text-blue-700'
                    onClick={e => {
                      if (isMobile) {
                        e.preventDefault();
                        copyEmail();
                      }
                    }}
                  >
                    support@saversapp.com
                  </p>
                ) : (
                  <a
                    href={'mailto:support@saversapp.com'}
                    className='text-blue-700'
                  >
                    support@saversapp.com
                  </a>
                )}
              </p>
              <p className='mt-6'>
                <strong>Non-personal information</strong>, that does not
                directly or indirectly reveal your identity or directly relate
                to an identifiable individual, such as statistical or aggregated
                information. Statistical or aggregated data does not directly
                identify a specific person, but we may derive non-personal
                statistical or aggregated data from personal information. For
                example, we may aggregate personal information to calculate the
                percentage of people accessing a specific feature of the
                Program.
              </p>
              <p className='mt-6'>
                <strong>Technical information</strong>, including your login
                information, browser/APP type and version, time zone setting,
                browser/APP plug-in types and versions, device operating system
                and platform, information about your internet connection, the
                equipment you use to access the Program, and usage details. We
                use cookies where applicable to collect and store a user’s
                program preferences, information for allowing program log-ins,
                and potential data to be used for advertising purposes.
              </p>
              <p className='mt-6'>
                <strong>
                  Non-personal details about your interactions with the Program
                </strong>
                , including the full Uniform Resource Locators (URLs),
                clickstream to, through and from our Sites (including date and
                time), products you viewed or searched for, page response times,
                download errors, length of visits to certain pages, page
                interaction information (such as scrolling, clicks, and
                mouse-overs), methods used to browse away from the page, or any
                phone number used to call our customer service number.{' '}
              </p>
              <p className='mt-6'>
                <strong>Transaction Data</strong>. When you participate in the
                Program by enrolling an eligible card (i.e. Visa, Mastercard)
                you will be asked to provide consent to have transactions on
                that enrolled card monitored by the respective card network and
                for that card network to share certain details of the monitored
                transactions with Savers App Inc., and with our technology
                vendor (“Program Vendor”). Each party will access this
                Transaction Data from your monitored transactions. This
                Transaction Data may include the date, time, amount, merchant
                category, and merchant name. You may revoke your consent and opt
                out of transaction monitoring on your enrolled cards at any time
                by going to your settings and disabling or removing the card
                from the Program.
              </p>
              <p className='mt-6'>
                When your card is enrolled with the network, it is done so
                securely and directly with the Payment Network. A unique
                identifier, a token, representing your enrolled card may be used
                by us, the Program Vendor, and offer partners. No one at any
                time will see your credit card number or related details. After
                enrollment, your Transaction Data and the unique identifier will
                be shared between us, the Program Vendor, and the card network
                associated with your enrolled card. No additional Personal
                Information is shared with these third parties about your Savers
                App account.
              </p>
            </div>
          </li>

          <li className='mb-5'>
            <h3 className='font-bold'>HOW WE USE YOUR INFORMATION</h3>{' '}
            <p className='mt-2'>
              We use information that we collect about you or that you provide
              to us, including any personal information:
            </p>
            <div className='flex mt-2 ml-4'>
              <ul className='list-disc ml-4'>
                <li> to present our Program and its contents to you;</li>
                <li>
                  to fulfill the purposes for which you provided the information
                  or that were described when it was collected, or any other
                  purpose for which you provide it;
                </li>
                <li>
                  to provide you with notices about your account/subscription,
                  including expiration and renewal notices;
                </li>
                <li>
                  to carry out our obligations and enforce our rights arising
                  from any contracts with you, including for billing and
                  collection or to comply with legal requirements;
                </li>
                <li>
                  to improve our Program, products or services, marketing, or
                  customer relationships and experiences;
                </li>
                <li>
                  in any other way we may describe when you provide the
                  information; or
                </li>
                <li>for any other purpose with your consent.</li>
              </ul>
            </div>
            <div className='mt-4'>
              <p>
                <strong>How we use your Transaction Data.</strong> When you
                participate in our Program by enrolling an eligible credit card
                (i.e. Visa, Mastercard), you will be required to provide consent
                to have transactions on your enrolled card monitored by the card
                network. That card network will share with us, and with the
                Program Vendor, the Transaction Data. Notwithstanding anything
                to the contrary in our Program Terms and our Privacy Policy, we
                and the Program Vendor will use your Transaction Data solely to:
              </p>
              <div className='ml-8'>
                <ul className='list-disc'>
                  <li>enable card-linked offers,</li>
                  <li>
                    identify offers that may be of interest to you based on
                    location,
                  </li>
                  <li>provide program notifications,</li>
                  <li>
                    calculate Cashback reward payment amounts for merchants to
                    make to the Receiving Cause, and
                  </li>
                  <li>
                    report in aggregate to merchants the success of their offers
                    in the Program.
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li className='mb-5'>
            <h3 className='font-bold'>
              PERMISSION FOR COLLECTION OF INFORMATION
            </h3>{' '}
            <div className='mt-2'>
              <p>
                By using the Program, you are hereby consenting to the
                collection of your information by us. The information in some
                cases may include personal and sensitive personal information.
                In case of collection of sensitive personal information, you
                will be required to confirm your acceptance of this Privacy
                Policy. By accepting the present Privacy Policy you are also
                representing that you are eighteen (18) years of age or above.
              </p>
              <p className='mt-4'>
                Please be aware that, if you do not allow us to collect
                information from you, we may not be able to deliver certain
                products and services to you, and some of our services may not
                be able to take account of your interests and preferences. By
                providing your information to us you are consenting to the use
                of such information by us in accordance with this Privacy
                Policy.
              </p>
            </div>
          </li>

          <li className='mb-5'>
            <h3 className='font-bold'>
              SHARING OF INFORMATION COLLECTED FOR THE PROGRAM
            </h3>{' '}
            <div className='mt-2'>
              <p>
                We may disclose aggregated information about the use of the
                Program, and information that does not identify any individual,
                without restriction.
              </p>
              <p className='mt-4'>
                We may disclose personal information that we collect or you
                provide as described in this privacy policy:
              </p>
            </div>
            <div className='ml-8 mt-4'>
              <ul className='list-disc'>
                <li>to our subsidiaries and affiliates; </li>
                <li>
                  in accordance with applicable law, to a buyer or other
                  successor in the event of a merger, divestiture,
                  restructuring, reorganization, dissolution, or other sale or
                  transfer of some or all of the Company’s assets, whether as a
                  going concern or as part of bankruptcy, liquidation, or
                  similar proceeding, in which personal information held by
                  Program Vendor about our customers and users is among the
                  assets transferred;{' '}
                </li>
                <li>
                  to contractors, service providers, and other third parties we
                  use to support our business (such as analytics and search
                  engine providers that assist us with the improvement and
                  optimization of our Sites) and who are contractually obligated
                  to keep personal information confidential, use it only for the
                  purposes for which we disclose it to them, and to process the
                  personal information with the same standards set out in this
                  policy;{' '}
                </li>
                <li>
                  to fulfill the purpose for which you provide it. For example,
                  if you give us an email address to use the "refer a friend"
                  feature of our Sites, we will transmit the contents of that
                  email and your email address to the recipients;{' '}
                </li>
                <li>
                  for any other purpose disclosed by us when you provide the
                  information;{' '}
                </li>
                <li>with your consent; </li>
              </ul>
            </div>
            <div className='mt-4'>
              <p>We may also disclose your personal information: </p>
              <div className='ml-8 mt-4'>
                <ul className='list-disc'>
                  <li>
                    to comply with any court order, law, or legal process,
                    including to respond to any government or regulatory
                    request, in accordance with applicable law;{' '}
                  </li>
                  <li>
                    to enforce or apply the Program Terms and other agreements,
                    including for billing and collection purposes; and/or{' '}
                  </li>
                  <li>
                    if we believe disclosure is necessary or appropriate to
                    protect the rights, property, or safety of us, our
                    customers, or others. This includes exchanging information
                    with other companies and organizations for the purposes of
                    fraud protection and credit risk reduction.
                  </li>
                </ul>
              </div>
              <p className='mt-4'>
                When you participate in the Program your Transaction Data and a
                token representing your enrolled card will be shared between us,
                the Program Vendor, and the card network associated with your
                enrolled card (i.e. Visa, Mastercard). No additional Personal
                Information is shared with these third parties about your
                account with us without your consent.
              </p>
            </div>
          </li>
          <li className='mb-5'>
            <h3 className='font-bold'>
              Personal Information from Users below 18 years of age
            </h3>
            <p className='mt-2'>
              We do not knowingly collect personal information from users below
              18 years of age. If you believe that such a user has provided
              personal information to us, please contact us via{' '}
              <a href='mailto:support@saversapp.com' className='text-blue-700'>
                support@saversapp.com
              </a>
              , so that we can identify and delete that user's personal
              information.
            </p>
          </li>
          <li className='mb-5'>
            <h3 className='font-bold'>Changes to Privacy Policy</h3>
            <p className='mt-2'>
              This Policy is current as of the effective date set forth above.
              We reserve the right to change this Policy from time to time
              consistent with applicable privacy laws and principles. If we make
              changes to this Policy we will notify you by revising the date at
              the top of this Policy and provide you with additional notice
              (such as adding a statement to the homepages of our Services or
              sending you an email notification to obtain your consent).
            </p>
          </li>
          <li className='mb-5'>
            <h3 className='font-bold'>QUESTIONS OR COMPLAINTS</h3>
            <p className='mt-2'>
              If you have any complaints or grievances regarding the information
              shared with us please feel free to contact us at{' '}
              <a href='mailto:support@saversapp.com' className='text-blue-700'>
                support@saversapp.com
              </a>
            </p>
          </li>
        </ol>
      </section>
    </div>
  );
}

export default PrivacyPolicyPage;
