import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from '../../assets/SVG-Icons';
import { Link } from 'react-router-dom';
import { images } from '../../constants/images';
import { OrganizationDetails } from '../../hooks/useFetchOrganizationDetails';
import Spinner from '../spinner/Spinner';

interface Props {
  data: OrganizationDetails | null;
  loading: boolean;
}

const NavBarLandingPage = ({ data, loading }: Props) => {
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const [isB2C, setIsB2C] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const { pathname } = useLocation();

  useEffect(() => {
    setIsB2C(true);
  }, []);
  useEffect(() => {
    const removeBackground = async () => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = data?.logo || ''; // Replace with your image source

      img.onload = () => {
        if (!canvasRef.current) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        if (!ctx) return;

        // Set canvas dimensions to match the image
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0);

        // Get image data for manipulation
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        // Loop through each pixel to remove the white background
        for (let i = 0; i < data.length; i += 4) {
          const r = data[i];
          const g = data[i + 1];
          const b = data[i + 2];

          // حساب متوسط السطوع
          const brightness = (r + g + b) / 3;

          // حساب الفرق بين القيم اللونية
          const diff1 = Math.abs(r - g);
          const diff2 = Math.abs(g - b);
          const diff3 = Math.abs(b - r);

          // شرط أفضل يحافظ على التفاصيل الفاتحة مع إزالة الخلفية
          if (brightness > 230 && diff1 < 20 && diff2 < 20 && diff3 < 20) {
            // استثناء أجزاء القلب داخل المصباح
            if (!(r > 220 && g > 180 && b > 180)) {
              data[i + 3] = 0; // جعل الخلفية شفافة
            }
          }
        }

        // Put the modified image data back onto the canvas
        ctx.putImageData(imageData, 0, 0);
      };

      img.onerror = error => {
        console.error('Failed to load the image', error);
      };
    };

    if (data?.logo) {
      removeBackground();
    }
  }, [data]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (data?.orgDisplayName) {
      document.title = data.orgDisplayName;
    }
  }, [data?.orgDisplayName]);

  return (
    <header
      className={`relative md:static flex items-center justify-between h-[120px] py-5 px-4 lg:mx-14 gap-6 smd:gap-4 md:gap-4 ${
        pathname === '/signup' ? 'mb-0 mt-6' : ''
      } ${isMenuOpen ? 'sticky top-0' : ''} sticky top-0 z-50 bg-white`}
      style={{ maxWidth: '100%', overflowX: 'hidden' }}
    >
      {/* Logo Section */}
      <div
        className='w-full  lg:w-fit flex justify-between items-center z-50 '
        // style={{ width: 'fit-content' }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <Link to={'/'}>
            {/* {Icons.logo({ width: '300' })} */}
            {/* {data?.logo && ( */}
            <canvas
              ref={canvasRef}
              className='max-w-52 md:max-w-64 max-h-20 '
            />
            {/* )} */}
            {/* <img src={Xdream} alt="" className="w-64 h-6" /> */}
          </Link>
        )}

        {/* Hamburger Icon */}
        {pathname !== '/signup' && (
          <button
            className='lg:hidden text-3xl z-50'
            onClick={toggleMenu}
            aria-label='Toggle Menu'
          >
            {isMenuOpen ? (
              Icons.closeIcon()
            ) : (
              <svg
                className='w-8 h-8'
                fill='#000000'
                stroke='#000000'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M4 6h16M4 12h16M4 18h16'
                ></path>
              </svg>
            )}
          </button>
        )}
      </div>

      {/* Navigation Menu */}
      {pathname !== '/signup' && (
        <nav
          className={`fixed top-0 left-0 w-full h-full bg-white z-40 transform transition-transform duration-300 lg:static lg:flex lg:items-center lg:justify-center lg:w-auto ${
            isMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'
          }`}
        >
          <ul className='flex flex-col h-full mt-[10rem] lg:mt-0 lg:flex-row items-center justify-start lg:justify-center gap-1 smd:gap-2 lg:gap-10'>
            {isB2C && (
              <div className='flex xl:gap-12 flex-col lg:flex-row gap-8 text-center'>
                <li
                  onClick={() => {
                    if (isMenuOpen) {
                      toggleMenu();
                    }
                    if (currentPath === '/') {
                      document.getElementById('how-it-works')?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    } else {
                      navigate('/#how-it-works');
                    }
                  }}
                  className='md:pb-0 text-base smd:mx-0 md:text-xl xmd:mx-2 md:mx-0 font-semibold cursor-pointer text-nowrap'
                >
                  How it works
                </li>
                <li
                  onClick={() => {
                    if (isMenuOpen) {
                      toggleMenu();
                    }
                    if (currentPath === '/') {
                      document.getElementById('benefits')?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    } else {
                      navigate('/#benefits');
                    }
                  }}
                  className=' text-base md:text-xl smd:mx-0 xmd:mx-2 md:mx-0 font-semibold cursor-pointer text-nowrap'
                >
                  Benefits
                </li>
                <li
                  onClick={() => {
                    if (isMenuOpen) {
                      toggleMenu();
                    }
                    if (currentPath === '/') {
                      document.getElementById('trust-us')?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    } else {
                      navigate('/#trust-us');
                    }
                  }}
                  className='md:pb-0 smd:mx-0 text-base md:text-xl  xmd:mx-2 md:mx-0 font-semibold cursor-pointer text-nowrap'
                >
                  Trust us
                </li>
                <li
                  onClick={() => {
                    navigate('/business-faq');
                    if (isMenuOpen) {
                      toggleMenu();
                    }
                    // if (currentPath === '/') {
                    //   document.getElementById('how-it-works')?.scrollIntoView({
                    //     behavior: 'smooth',
                    //     block: 'start',
                    //   });
                    // } else {
                    //   navigate('/#how-it-works');
                    // }
                  }}
                  className='pb-12 md:pb-0  smd:mx-0 text-base md:text-xl  xmd:mx-2 md:mx-0 font-semibold cursor-pointer'
                >
                  FAQ
                </li>
              </div>
            )}
          </ul>
        </nav>
      )}
      <div className='hidden lg:flex flex-col md:flex-row min-w-[15rem] gap-4 max-w-72  items-center'>
        <Link to={data?.appDeepLink + ''} target='_blank'>
          <img src={images.iosLogo} alt='' className='h-[48px]' />
        </Link>
        <Link to={data?.googleDeepLink + ''} target='_blank'>
          <img src={images.androidLogo} alt='' className='h-[48px]' />
        </Link>
      </div>
    </header>
  );
};

export default NavBarLandingPage;
