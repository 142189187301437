interface EnvConfig {
  REACT_APP_ENV: string;
  REACT_APP_ADMIN_BASE_URL: string;
  REACT_APP_ADMIN_X_API_KEY: string;
  REACT_APP_API_BASE_URL: string;
  REACT_APP_API_X_API_KEY: string;
}

const devConfig: EnvConfig = {
  REACT_APP_ENV: "prod",
  REACT_APP_ADMIN_BASE_URL: "https://adminapi.madrewards.com/cpp/v1",
  REACT_APP_ADMIN_X_API_KEY: "3vSImub1KH6MkYZMOhsai52qXUBwHc5S3Cb2di6q",
  REACT_APP_API_BASE_URL: "https://prodapi.madrewards.com/clo/v2",
  REACT_APP_API_X_API_KEY: "40TuzE1zkm6Kx0D0uyzSt7IsBI3IGoz8fDCiHgJ6",
};

export default devConfig;
